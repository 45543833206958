@use "../variables" as gstyles;

.mat-mdc-form-field-infix {
    min-height: 0 !important;
}

// With Material 15, the default space between the letters of the button label is wider. We remove this behavior
//.mdc-button, .mat-mdc-form-field, .mat-mdc-form-field-input-control, .mdc-tab, .mdc-list-item__primary-text,
//.mat-expansion-panel-content, .mdc-data-table__cell, .mat-mdc-select, .mat-mdc-floating-label,
//.mat-mdc-form-field-error-wrapper {
//    letter-spacing: normal !important;
//}

// In Material 15, those buttons are defined as "height: 36px". When we have a label displayed on multiple rows
// (ex: "Chauffeurs disponibles" in the DriverActivityComponent), this breaks the UI.
// We remove this behavior to allow the button to resize itself to fit all its content
.mat-mdc-raised-button {
    min-height: 36px; // Default size for Material Buttons
    height: auto !important; // But they can expand if their content needs to span on multiple rows
}

// We increased the font-size for a better compatibility with Material 15.
// The new default font-size is too big for <mat-menu> items -> We reduce it
// Same for input fields and select dropdowns
.mat-mdc-menu-content *, .mat-mdc-form-field-input-control, .mat-mdc-floating-label {
    font-size: gstyles.$font-size-base !important;
}

// In Material 15, "mat-expansion-panel-content" overrides the default font-size. We reset this behavior
//.mat-expansion-panel-content {
//    font-size: gstyles.$font-size-base !important;
//}

// Legacy display of a MatDatatableHeader
//.mdc-data-table__header-cell {
//    font-size: 12px !important;
//    color: #0000008a !important;
//}

// Legacy behavior of the MatListItems before Material 15
// Without this, the Credit Cards list would be completely broken
.mat-mdc-list-item .mdc-list-item__content .mdc-list-item__primary-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    position: relative;
    height: inherit;
}

/**
    Without this, our CustomFont would be overridden by the default one in <mat-option> (at least, in our
    Autocomplete components, for example : <msl-address-autocomplete>)
 */
.mat-mdc-option .mdc-list-item__primary-text {
    font-family: CustomFont !important;
}

// Material 15 adds a default top padding of 16px, we remove it
.mat-mdc-card-header {
    padding-top: 0 !important;
}

// Material 15 adds a default padding of 16px around <mat-card-content>, we remove it
.mat-mdc-card-content {
    padding: 0 !important;
}

// We replace it by the legacy padding around the whole Mat-Card before Material 15
.mat-mdc-card {
    padding: 16px;
}

// No padding for the Autocomplete Panel, nor for the ErrorWrapper (to have as much space as possible to display
// any error message below a MatFormField)
.mat-mdc-form-field-error-wrapper, .mat-mdc-autocomplete-panel {
    padding: 0 !important;
}

// Legacy visual of Mat-Form-Field before Material 15
.mat-mdc-text-field-wrapper {
    padding: 0 !important;

    /**
        This Legacy rule is no longer working if the MatFormField contains a floating label : The label
        overflows on the content.
        To avoid that, we only apply this legacy rule on fields that do not have a <mat-label> (usually they
        have a placeholder which behaves similarly (but not exactly the same : On <mat-select> the placeholder
        is no longer displayed if a value is selected, while the mat-label floats over the selected value))

        On 08/08/2023, we refine the rule to exclude it from "outlined" fields (such as the search fields in trips list,
        clients list, and so on...) -> https://mysamcab.atlassian.net/browse/MYS-6355
     */
    &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix:not(:has(.mat-mdc-floating-label)) {
        padding: 0.4em 0 !important;
    }

    /**
        https://mysamcab.atlassian.net/browse/MYS-6355
        For "outlined" fields, we add a little bit more padding horizontally, to make the text more readable
     */
    &.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding: 0.4em 0.6em !important;
    }

    /**
        With Material 15, all MatFormField that have a floating label (= a <mat-label>) are too big by default :
        They have a line-height/height of 24px and the default font-size is 16px.
        Since we reduced the default font-size of MatFormFields (using "gstyles.$text-sm"), we should also reduce the
        line-height/height accordingly, in order to avoid blank spaces and too big margins.
        Therefore, we multiply the font-size of MatFormFields (= "gstyles.$text-sm") by an arbitrary factor
        (for now : 1.2) to define a dynamic line-height/height (if we change "gstyles.$text-sm", the line-height/height
        will be updated accordingly)
     */
    .mat-mdc-form-field-infix:has(.mat-mdc-floating-label) {

        // SCSS does not allow us to write $height: calc(gstyles.$text-sm * 1.2), we need to use an intermediate variable
        $textSize: gstyles.$text-sm;
        $height: calc($textSize * 1.2);

        // Now, we will use this $height variable for the line-height of the current MatFormField...
        line-height: $height;
        .mat-mdc-select {
            // ... as well as the height/line-height of any <mat-select> in this MatFormField
            height: $height;
            line-height: $height;
        }
    }
}

/**
    Our checkboxes should be a little smaller than what's defined by Material 15 (e.g. when writing a discussion
    for a Driver -> The checkbox allowing to tick "No trip bound o this discussion")
 */
:root {
    --mdc-checkbox-state-layer-size: 16px;
}

/**
    Without this, our rule of "centering" the content of a button would not work anymore
    Therefore, whenever a button has the "d-flex" class, we also add this "flex" behavior to its inner
    mdc-button__label
 */
button.d-flex .mdc-button__label {
    display: flex;
    align-items: center;
}

/**
    Removes the grey-ish background of Mat-Form-Field
 */
.mat-mdc-form-field-focus-overlay, .mdc-text-field--filled {
    background-color: transparent !important;
}

.mdc-form-field {
    line-height: 24px !important;
}

/**
    The "Subscript Wrapper" (container of the "hint" and "errors" on a MatFormField) should have a line-height of
    12px, as in the Legacy display (before Material 15)
 */
.mat-mdc-form-field-subscript-wrapper {
    line-height: 12px !important;
}

/**
    Overrides the default "mat-icon-button" of Material 15 -> Reset to the Legacy behavior
 */
.mat-mdc-icon-button.mat-mdc-button-base {
    height: 1.5em !important;
    width: 1.5em !important;
    padding: 0 !important;
}

/**
    Without this, the <textarea> tags would have a line-height too big.
    This way, we reduce it and we reset it to the way it was before Material 15
 */
textarea {
    line-height: normal;
}
