.white-spinner circle { stroke: #fff !important; }

/**
    In our apps, all the Form Fields should be 100% wide, and their width will be actually controlled by their
    container
    Since this is an "app-wide" directive, no need to prefix the ::ng-deep with :host -> This directive will be
    applied everywhere.
 */
::ng-deep .mat-mdc-form-field { width: 100%; }

/**
    This rule allows to wrap on a new line when a \n character is found in the content of the tooltip
 */
::ng-deep .mat-mdc-tooltip {
    white-space: pre-line !important;
}
