/**
 * Before Bootstrap 5, a default "scaffolding.less" file was loaded along Bootstrap (in order to homogenize default
 * values among all browsers - See https://github.com/less/less-docs/blob/master/styles/bootstrap/scaffolding.less
 * In order to keep this behavior with Bootstrap 5 (and not having to update all our styles), we converted this
 * LESS file into a SCSS file (using https://less2scss.awk5.com), and added it in the project here.
 * We also added a bunch of @use directives at the top of the file, in order to be explicit about the source of
 * any variable that might be used in this file
 */

@use 'image' as img;

/**
    See https://github.com/twbs/bootstrap/issues/23451 - It is needed by bootstrap/scss/variables..........
    Otherwise we would get an "Undefined mixin. @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");" errors
    Furthermore, since an @import cannot be placed before a @use directive, we need to @import bootstrap/scss/variables
    as well, instead of just @use-ing it............
 */
@import '../node_modules/bootstrap/scss/_functions.scss';
@import '../node_modules/bootstrap/scss/_variables.scss';

// region Scaffolding.less

//
// Scaffolding
// --------------------------------------------------

// Body reset

html {
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    font-size: $font-size-base;
    line-height: $line-height-base;
    background-color: $body-bg;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}


// Links

a {
    color: $link-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $link-hover-color;
        text-decoration: underline;
    }
}


// Images

img {
    vertical-align: middle;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
    @include img.img-responsive();
}

// Perfect circle
.img-circle {
    border-radius: 50%; // set radius in percents
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// endregion

/**
 * We removed twitter-bootstrap but the css code followed are usefully for display a caret before dropdown
 */

// Dropdown arrow/caret
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}
