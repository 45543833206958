/* You can add global styles to this file, and also import other style files */

//== Vendor

@import "../../../../../node_modules/bootstrap/dist/css/bootstrap.css";

@import '../../../node_modules/font-awesome/css/font-awesome.css';

@import '../../../node_modules/ng-material-floating-button/mfb/dist/mfb.css';

/**
    By default, the pOverlayPanel has a z-index of 1001 (above the default z-index of cdk-overlay-container which is 1000)
    However, cdk-overlay-container is the overlay class of the MatTooltips, which we use and we want them to be above
    any pOverlayPanel that we define.
    Therefore, we change the behavior of pOverlayPanel to set the z-index to 999 (below cdk-overlay-container)
 */
.p-overlaypanel {
    z-index: 999 !important;
}
