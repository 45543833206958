@mixin msl-button {
  // We want the button to keep enough space to set the spinner size to 25px and still have some extra space around it
  min-height: 35px;

  // Instead of a big line-height, we set the font-size and the line-height to the same value...
  font-size: 14px;
  line-height: 24px;

  // ... and we add some extra padding on top/bottom to compensate
  padding-top: 5px;
  padding-bottom: 5px;

  white-space: normal !important; // To wrap on multiple lines if the text is too big
}

/**
    Copied/Pasted from bootstrap/mixins/clearfix in the original Centric Template
 */
// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}
