/**
    Even though this SCSS is in MslSharedStyles, it is NOT BUILT with the library !
    It is built & included automatically into Alfred & Liberty Order, through "angular.ts"
 */
@use '../../../../../node_modules/@angular/material/core/theming/all-theme' as mat-all-themes;
@use '../../../../../node_modules/@angular/material/core/theming/theming' as mat-theming;
@use '../../../../../node_modules/@angular/material/core/theming/palette' as mat-palette;
@use '../../../../../node_modules/@angular/material' as mat;

$dynamic-theme-primary: (
    50 : var(--theme-primary-50),
    100 : var(--theme-primary-100),
    200 : var(--theme-primary-200),
    300 : var(--theme-primary-300),
    400 : var(--theme-primary-400),
    500 : var(--theme-primary-500),
    600 : var(--theme-primary-600),
    700 : var(--theme-primary-700),
    800 : var(--theme-primary-800),
    900 : var(--theme-primary-900),
    A100 : var(--theme-primary-A100),
    A200 : var(--theme-primary-A200),
    A400 : var(--theme-primary-A400),
    A700 : var(--theme-primary-A700),
    contrast: (
        50: var(--theme-primary-contrast-50),
        100: var(--theme-primary-contrast-100),
        200: var(--theme-primary-contrast-200),
        300: var(--theme-primary-contrast-300),
        400: var(--theme-primary-contrast-400),
        500: var(--theme-primary-contrast-500),
        600: var(--theme-primary-contrast-600),
        700: var(--theme-primary-contrast-700),
        800: var(--theme-primary-contrast-800),
        900: var(--theme-primary-contrast-900),
        A100: var(--theme-primary-contrast-A100),
        A200: var(--theme-primary-contrast-A200),
        A400: var(--theme-primary-contrast-A400),
        A700: var(--theme-primary-contrast-A700),
    )
);

$dynamic-theme-accent: (
    50 : var(--theme-accent-50),
    100 : var(--theme-accent-100),
    200 : var(--theme-accent-200),
    300 : var(--theme-accent-300),
    400 : var(--theme-accent-400),
    500 : var(--theme-accent-500),
    600 : var(--theme-accent-600),
    700 : var(--theme-accent-700),
    800 : var(--theme-accent-800),
    900 : var(--theme-accent-900),
    A100 : var(--theme-accent-A100),
    A200 : var(--theme-accent-A200),
    A400 : var(--theme-accent-A400),
    A700 : var(--theme-accent-A700),
    contrast: (
        50: white,
        100: white,
        200: white,
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
        //50: var(--theme-accent-contrast-50),
        //100: var(--theme-accent-contrast-100),
        //200: var(--theme-accent-contrast-200),
        //300: var(--theme-accent-contrast-300),
        //400: var(--theme-accent-contrast-400),
        //500: var(--theme-accent-contrast-500),
        //600: var(--theme-accent-contrast-600),
        //700: var(--theme-accent-contrast-700),
        //800: var(--theme-accent-contrast-800),
        //900: var(--theme-accent-contrast-900),
        //A100: var(--theme-accent-contrast-A100),
        //A200: var(--theme-accent-contrast-A200),
        //A400: var(--theme-accent-contrast-A400),
        //A700: var(--theme-accent-contrast-A700),
    )
);

$dynamic-light-theme-background: (
    status-bar: map_get(mat-palette.$grey-palette, 300),
    app-bar: map_get(mat-palette.$grey-palette, 100),
    background: map_get(mat-palette.$grey-palette, 50),
    hover: rgba(black, 0.04),
    card: white,
    dialog: white,
    disabled-button: rgba(black, 0.12),
    raised-button: white,
    focused-button: mat-palette.$dark-focused,
    selected-button: map_get(mat-palette.$grey-palette, 300),
    selected-disabled-button: map_get(mat-palette.$grey-palette, 400),
    disabled-button-toggle: map_get(mat-palette.$grey-palette, 200),
    unselected-chip: map_get(mat-palette.$grey-palette, 300),
    disabled-list-option: map_get(mat-palette.$grey-palette, 200),
);

$dynamic-light-theme-foreground: (
    base: black,
    divider: mat-palette.$dark-dividers,
    dividers: mat-palette.$dark-dividers,
    disabled: mat-palette.$dark-disabled-text,
    disabled-button: rgba(black, 0.26),
    disabled-text: mat-palette.$dark-disabled-text,
    elevation: black,
    hint-text: mat-palette.$dark-disabled-text,
    secondary-text: mat-palette.$dark-secondary-text,
    icon: rgba(black, 0.54),
    icons: rgba(black, 0.54),
    text: rgba(black, 0.87),
    slider-min: rgba(black, 0.87),
    slider-off: rgba(black, 0.26),
    slider-off-active: rgba(black, 0.38),
);

$color-theme: (
    primary: mat-theming.define-palette($dynamic-theme-primary),
    accent: mat-theming.define-palette($dynamic-theme-accent),
    warn: mat-theming.define-palette(mat-palette.$red-palette),
);

$primary-color: var(--theme-primary-500);
$accent-color: var(--theme-accent-500);

// Define themed-stylesheets here:

.primary-color {
    color: $primary-color !important;
}

.accent-color {
    color: $accent-color !important;
}

.primary-bg-color {
    background-color: $primary-color !important;
}

.accent-bg-color {
    background-color: $accent-color !important;
}

.primary-border-color {
    border-color: $primary-color !important;
}

.primary-box-shadow {
    box-shadow: $primary-color !important;
}

/*****************************************************************************
    Loading the custom fonts
 *****************************************************************************/

/**
    Default font, applied everywhere, except on <mat-icon> which needs to use their own fonts (to be displayed
    as icons and not as text), as well as "material-icons" classes (used before the introduction of <mat-icon>)
    Other exception : The Font-Picker in RegisterLm should also keep its own fonts (in order to present them to the
    user) -> We don't apply our own font on ".font-info" and ".font-name" elements (which are the container class of
    any font displayed by Ngx-Font-Picker)

    Works alongside with the ThemeableComponent which defines the value of this "CustomFont"
 */
*:not(mat-icon):not(.material-icons):not(.material-icons-outlined):not(.font-info):not(.font-name) {
    font-family: CustomFont;

    /**
        Without this, the <mat-card-title> and <mat-card-subtitle> tags will use a default Roboto font instead of
        our custom one.
        We force it using these directives
     */
    --mat-card-title-text-font: CustomFont;
    --mat-card-subtitle-text-font: CustomFont;
}

/*****************************************************************************
    End loading the custom fonts
 *****************************************************************************/

////////////////////////////////////////////////////////////////////////////////////////////////
// region Angular Material Themes

/**
    FIX ANGULAR 15 -> Issue raised on https://github.com/angular/components/issues/26056
    and fixed here https://github.com/angular/components/issues/26056#issuecomment-1412183516

    Without those, the buttons labels are always displayed in black, whatever the contrast of the current palette.
    To fix this, we need to force the usage of our contrast values to override the Material Buttons Theme.
 */
.mat-mdc-unelevated-button.mat-primary {
    &.mat-primary {
        --mdc-filled-button-label-text-color: var(--theme-primary-contrast-500) !important;
    }

    &.mat-accent {
        --mdc-filled-button-label-text-color: var(--theme-accent-contrast-500) !important;
    }
}

.mat-mdc-raised-button {
    &.mat-primary {
        --mdc-protected-button-label-text-color: var(--theme-primary-contrast-500) !important;
    }

    &.mat-accent {
        --mdc-protected-button-label-text-color: var(--theme-accent-contrast-500) !important;
    }
}

// Same problem for checkboxes : https://github.com/angular/components/issues/26605#issuecomment-1427805936
.mat-mdc-checkbox {
    &.mat-primary {
        --mdc-checkbox-selected-checkmark-color: var(--theme-primary-contrast-500) !important;
    }

    &.mat-accent {
        --mdc-checkbox-selected-checkmark-color: var(--theme-accent-contrast-500) !important;
    }
}

// Aaaand same problem for our FABs (on Liberty Order -> The "My position" FAB on the EstimationCard)
.mat-mdc-mini-fab {
    &.mat-primary {
        --mat-mdc-fab-color: var(--theme-primary-contrast-500) !important;
    }

    &.mat-accent {
        --mat-mdc-fab-color: var(--theme-accent-contrast-500) !important;
    }
}

// endregion Angular Material Themes
////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////
// region Angular Material Typography

/**
    Those themes are the ones that are the closest to the original Alfred
 */

$buttonTheme: mat.define-typography-level(
    $font-size: 14px,
    $line-height: 36px,
    $font-weight: 500
);

$body1Theme: mat.define-typography-level(
    $font-size: 14px,
    $line-height: 24px,
    $font-weight: 500
);

$body2Theme: mat.define-typography-level(
    $font-size: 14px,
    $line-height: 20px
);

/**
    Error messages (<mat-error>)
 */
$captionTheme: mat.define-typography-level(12px);

$dynamic-app-theme: mat-theming.define-light-theme((
    color: $color-theme,
    typography: mat.define-typography-config(
        $button: $buttonTheme,
        $body-1: $body1Theme,
        $body-2: $body2Theme,
        $caption: $captionTheme
    ),
    /**
        https://m2.material.io/design/layout/applying-density.html#components
        We keep the default density of 0, because even though setting it to a smaller value would allow us to fit more
        info on the screen, Material 15 hides the <mat-label> on smaller densities (!!!)
        Since we obviously want our labels to be displayed, we keep a density of 0
     */
    density: 0,
));
@include mat.all-component-themes($dynamic-app-theme);
@include mat.core();

// endregion Angular Material Typography
////////////////////////////////////////////////////////////////////////////////////////////////
