/**
    Even though this SCSS is in MslSharedStyles, it is NOT BUILT with the library !
    It is built & included automatically into Alfred & Liberty Order, through "angular.ts"
 */

/**
    Copied/Pasted from https://codepen.io/jczimm/pen/vEBpoL
 */
$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;

#loader-before-any-angular-display {

    /**
        Arbitrary values :
        - The loader is 10% wide, so we need to place it 45% to the left in order for it to be perfectly centered
        - We place it 35% to the top, in order for it to be "almost-centered" (near the center but a little bit
        closer to the top, it seems prettier)
     */
    position: absolute;
    top: 35%;
    left: 45%;
    width: 10%;

    &:before {
        content: '';
        display: block;
        padding-top: 100%;
    }

    svg {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;

        circle {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            stroke-linecap: round;
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {
    100%,
    0% {
        stroke: $red;
    }
    40% {
        stroke: $blue;
    }
    66% {
        stroke: $green;
    }
    80%,
    90% {
        stroke: $yellow;
    }
}