@use "msl-shared-styles/global-styles" as gstyles;
@import '@swimlane/ngx-datatable/index';
@import '@swimlane/ngx-datatable/themes/material';
@import '@swimlane/ngx-datatable/assets/icons';

.ngx-datatable.material {
    background-color: transparent;
    box-shadow: 0 0 0 #000;

    // We add a small border between the rows of the datatable
    .datatable-row-wrapper:not(:first-child) {
        .datatable-body-row {
            border-top: 1px solid rgba(0, 0, 0, 0.3);
        }
    }
}

.ngx-datatable.scroll-vertical {
    height: 300px;
}

// change color of active cells selected
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
    background-color: gstyles.$brand-primary;
    .datatable-body-cell {
        color: #fff;
    }
}

/**
    The version 17 of @swimlane/ngx-datatable brings a new sort button (with a content defined with the pseudo-selector
    ::before).
    We don't want to display it, as it duplicates our own sort mechanism
 */
.sort-btn::before
{
    display: none;
}

// remove link decoration
.datatable-icon-right {
    text-decoration: none !important;
}

// align table with no data legend
.ngx-datatable.material .empty-row {
    text-align: center;
    margin-top: 20px;
}

/**
    Added by MySam
 */
.datatable-body-cell.text-center {
    text-align: center !important;
}

// We disable the hover behavior and we display a pointer
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background-color: unset;
    cursor: pointer;
}

/**
    We override the new default behavior of Material, which displays a border-bottom under the datatable header
 */
.ngx-datatable.material .datatable-header
{
    border-bottom: none;
}

/**
    Mixin defined to update the datatable header height from anywhere in our app (using the
    $selector parameter to encapsulate the datatable(s) we want to update
 */
@mixin setDatatableHeaderHeight($selector, $height) {
    #{$selector} {
        .datatable-header-cell, .datatable-header {
            height: $height !important;
            overflow:visible !important;
        }
    }
}

/**
    And setting a default 100px height for all datatables in our app
 */
@include setDatatableHeaderHeight(":root", 100px);

/**
    Needed to avoid the MultiSelects to be displayed behind the datatable
    z-index: 10 or under would display the datatable rows above our MultiSelects
 */
.datatable-row-center {
    z-index: 11;
}

/**
    We set a big "min-height" on most of our ngx-datatables.
    Otherwise, if there is few data (e.g. on Liberty Driver, the clients/drivers list might have few elements),
    the dropdowns (filter by status, by vehicle type...) cannot be bigger than the height of the containing datatable.
    Therefore, to give more space to our dropdowns, we just increase the minimum height of our datatables.
 */
ngx-datatable.minHeight50vh {
    min-height: 50vh;
}
